import React from "react";
import "./Services.scss";
import { BannerTitle } from "../../components/BannerTitle/BannerTitle";
import { Title } from "../../components/Title/Title";
import { managementSystem } from "./Data";
import ima from "../../assets/banners/cleaningBathBanner.png";

export const Services = () => {
	return (
		<>
			<BannerTitle title="Services" ima={ima} size="cover" position="initial" />

			{/* CLEANING SECTION */}
			<div className="box">
				<section className="cleaningSection">
					<div className="content">
						<img
							alt="presentation"
							src={require("../../assets/images/cleaning_home_presentation.jpg")}
						/>
						<div>
							<Title text="Cleaning" />
							<p>
								Adept Services start with a full understanding of your specific
								requirements and then develop a detailed cleaning, safety and
								environmental plan for every area of the site.
							</p>
							<p>
								We have the expertise to clean a multitude of different
								environments and conditions to the required service outcomes. We
								implement strong systems and procedures to ensure that we
								schedule and supervise our staff to provide the varying
								frequencies of cleaning for different areas, whilst maintaining
								a quality delivery of cleaning services.
							</p>
						</div>
					</div>
				</section>
				{/* TECHNOLOGY SECTION */}
				<section className="techSection">
					<div className="content">
						<div>
							<Title text="Technology" />
							<p>
								Adept services strong IT capablities enable us to develop
								systems that are designed to serve you better. Our proprietary
								services platform streamlines various business processes, so
								that you benefit from an efficent and integrated service
								delivery that is customised to suit your reporting and business
								needs.
							</p>
							<p>
								Employees are equipped with the latest tools including a hand
								held device which automates numerous funcitions and provides you
								with real time reports. The platforms features are application
								driven and include service delivery confirmation, activity
								reporting field evaluation, risk assessment, shift logs, GPS
								tracking and standard communication functionalities such as a
								phone, camera, text, and email.
							</p>
						</div>
						<img
							alt="presentation"
							src={require("../../assets/images/PhotoTecnology_HomeAnd_Services.jpg")}
						/>
					</div>
				</section>
				<section className="maintenanceSection">
					<div className="content">
						<img
							alt="presentation"
							src={require("../../assets/images/PhotoMaintennace_HomeAnd_Services.jpg")}
						/>
						<div>
							<Title text="Maintenance" />
							<p>
								Adept Maintenance services provides an extensive range of
								maintenance services to industrial sites and facilities. We take
								a whole-of-life- approach to asset management and maintenance,
								with the objective of achieving meaningful outcomes for our
								customes.
							</p>
							<p>
								Our Key focus is to align our objectives with those of our
								customes, in other words, we treat your assets as if they were
								our own. This approach ultimately determines our success and
								allows you to focus on your core business. We have extensive
								experience in helping our customers maximise asset Utilization,
								continuosly identifying and implementing initiatives for
								improvement, as well as aiding in achieving sustained
								operational excellence.
							</p>
						</div>
					</div>
				</section>
				{/* INTERNAL MANGEMENT SYSTEM */}
				<section className="listSection">
					<Title text="Internal Management System" />
					<div className="listWrapper">
						<ul>
							{managementSystem.map((item) => {
								return (
									<>
										<div className="liContainer" key={item.id}>
											<img src={item.ima} alt="icon-arrow" />
											<li key={item.id}>{item.text}</li>
										</div>
									</>
								);
							})}
						</ul>
					</div>
				</section>
				{/* GALLERY SECTION */}
				<section></section>
			</div>
		</>
	);
};
