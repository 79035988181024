import React from "react";
import "./Title.scss";

type props = {
	text: string;
};

export const Title = ({ text }: props) => {
	return <h2 className="sectionTitle">{text}</h2>;
};
