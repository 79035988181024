import React, { useState } from "react";
import "./BurgerMenu.scss";
import { Link } from "react-router-dom";

export const BurgerMenu = () => {
	const [active, setActive] = useState(false);

	const handleActive = () => {
		if (active === false) {
			setActive(true);
		} else {
			setActive(false);
		}
	};

	return (
		<>
			<span onClick={() => handleActive()} className="burger-menu">
				<div></div>
			</span>
			{active ? (
				<div className="modal-menu">
					<span onClick={() => handleActive()} className="x">
						<div></div>
					</span>
					<ul>
						<li onClick={() => handleActive()}>
							<Link to="/">
								<p>Home</p>
							</Link>
						</li>
						<li onClick={() => handleActive()}>
							<Link to="/about">About</Link>
						</li>

						<li onClick={() => handleActive()}>
							<Link to="/services">Services</Link>
						</li>

						<li onClick={() => handleActive()}>
							<Link to="/contact-us">Contact Us</Link>
						</li>
					</ul>
				</div>
			) : null}
		</>
	);
};
