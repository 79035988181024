import React from "react";
import "./Navbar.scss";
//adds
import { Logo } from "../Logo/Logo";
import { Menu } from "./Menu/Menu";
import { BurgerMenu } from "./BurgerMenu/BurgerMenu";

export const Navbar = () => {
	return (
		<nav className="navbar">
			<Logo />
			<Menu />
			<BurgerMenu />
		</nav>
	);
};
