import React, { useState, useEffect } from "react";
import "./App.scss";
//ads
import { BrowserRouter, Routes, Route } from "react-router-dom";
//components
import { Navbar } from "./components/NavBar/NavBar";
//pages
import { Home } from "./pages/home/Home";
import { Footer } from "./components/Footer/Footer";
import { About } from "./pages/About/About";
import { Services } from "./pages/Services/Services";
import { ContactUs } from "./pages/ContactUs/ContactUs";
import { LoaderPage } from "./pages/LoaderPage/LoaderPage";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
	apiKey: "AIzaSyAtfDc1IRvgwjeDZcY3rSu72u5W7_MU1Lo",

	authDomain: "adept-1814d.firebaseapp.com",

	projectId: "adept-1814d",

	storageBucket: "adept-1814d.appspot.com",

	messagingSenderId: "1041020112352",

	appId: "1:1041020112352:web:fb49931184293fee8eaf85",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

function App() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		}
	}, [loading]);

	if (loading) return <LoaderPage />;

	return (
		<BrowserRouter>
			<div className="App">
				<Navbar />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/services" element={<Services />} />
					<Route path="/contact-us" element={<ContactUs />} />
				</Routes>
				<Footer />
			</div>
		</BrowserRouter>
	);
}

export default App;
