import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/logos/Adept_Service_LOGO_BlueAndWhite.png";

export const Footer = () => {
	return (
		<footer className="footer-section">
			{/* logo */}
			<div className="logo-glow-container">
				<img className="logoGlow" src={logo} alt="logoGlow" />
			</div>
			{/* navigation */}
			<div className="footer-menu-container">
				<h4>Menu</h4>
				<ul>
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/about">About Us</Link>
					</li>
					<li>
						<Link to="/services">Services</Link>
					</li>
					<li>
						<Link to="/gallery">Gallery</Link>
					</li>
					<li>
						<Link to="/contact">Contact Us</Link>
					</li>
				</ul>
			</div>
			{/* contact */}
			<div className="footer-contact-container">
				<h4>Contact Us</h4>
				<p>9 George Street North Strathfield</p>
				<p>NSW 2137</p>
				<p>
					<strong>T:</strong>1300 029 262
				</p>
				<p>
					<strong>E:</strong>info@adept-services.com.au
				</p>
			</div>
		</footer>
	);
};
