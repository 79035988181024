import React from "react";
import "./Menu.scss";
import { Link } from "react-router-dom";

export const Menu = () => {
	return (
		<ul className="menu">
			<li>
				<Link to="/">
					<p>Home</p>
				</Link>
			</li>
			<li>
				<Link to="/about">About</Link>
			</li>

			<li>
				<Link to="/services">Services</Link>
			</li>
			{/* <li>
				<Link to="/gallery">Gallery</Link>
			</li> */}
			<li>
				<Link to="/contact-us">Contact Us</Link>
			</li>
		</ul>
	);
};
