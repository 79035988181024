import React from "react";
import "./About.scss";
//components
import { Title } from "../../components/Title/Title";
//data
import { data } from "./industriesData";
import { BannerTitle } from "../../components/BannerTitle/BannerTitle";
import ima from "../../assets/banners/cleaningKeyboardBanner.png";
import imaTwo from "../../assets/images/adeptBio.png";

export const About = () => {
	return (
		<div>
			<BannerTitle title="About Us" ima={ima} size="cover" position="bottom" />
			{/* ADEPT SERVICES SECTION */}
			<section className="aboutFirstSection">
				<div className="contentContainer">
					<div className="leftCol">
						<Title text="Adept Services" />
						<p>
							Adept Property Services PTY LTD is an Australian commercial and
							industrial cleaning company committed to providing high quiality,
							zero harm, cost efficient cleaning and support services.
						</p>
						<p>
							Every aspect of our business is tailored to meet our client`s
							individual needs. We have a committed and driven customer focused
							team of professionals who implement strategic support to Retail,
							Commercial and Indistrial property Portfolios. Our Management Team
							have in-depth experience in managing a wide variety of client
							requirements.
						</p>
						<p>
							We offer a complete unified facilities management framework wich
							can provide services to support your facilities to improve your
							premises including hygiene and washroom services, high pressure
							cleaning, carpet and window cleaning, grounds maintenance, pest
							control and waste management services.
						</p>
					</div>
					<div className="rCol">
						<div className="imaContainer">
							<img
								className="photoPresentation"
								alt="cleaning_guy"
								src={imaTwo}
							/>
						</div>
					</div>
				</div>
			</section>
			{/* OUR TEAM SECTION */}
			<section className="ourTeamSection">
				<div className="content">
					<div className="leftCol">
						<div className="imaContainer">
							<img
								alt="team"
								className="photoPresentation"
								src={require("../../assets/images/ourTeam.png")}
							/>
						</div>
					</div>

					<div className="textWrapper">
						<Title text="Our Team" />
						<p>
							Every aspect of our business is tailored to meet our client`s
							individual needs. We have a committed and driven customer focused
							team of professionals who implement strategic support to Retail,
							Commercial and industrial property portfolios. Our Management Team
							have in-depth experience in managing a wide variety of client
							requirements.
						</p>
					</div>
				</div>
			</section>
			{/* INDUSTRIES SERVICED SECTION */}
			<section className="industriesSection">
				<Title text="Industries Serviced" />

				<div className="listContainer">
					{data.map((item) => {
						return (
							<div key={item.id}>
								<img alt={item.text} src={item.ima} />
							</div>
						);
					})}
				</div>
			</section>
		</div>
	);
};
