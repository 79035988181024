import React from "react";
import "./Form.scss";

export const Form = () => {
	const adeptEmail = "https://formsubmit.co/info@adept-services.com.au";

	return (
		<form action={adeptEmail} method="POST" className="formContainer">
			<div className="nameRow">
				<input placeholder="Name" type="text" name="user_name" required />

				<input placeholder="E-mail" type="email" name="user_email" required />
			</div>
			<div className="messageCol">
				<input placeholder="Subject" type="text" name="subject" />

				<textarea placeholder="Message" name="message" required />
			</div>

			<input className="inputButton" type="submit" value="Send" />
		</form>
	);
};
