import React from "react";
import "./ContactUs.scss";
import { BannerTitle } from "../../components/BannerTitle/BannerTitle";
import ima from "../../assets/images/PhotoBanner_ContactUs.png";
import { Form } from "../../components/Form/Form";

export const ContactUs = () => {
	return (
		<div className="contactSection">
			<BannerTitle
				title="Contact Us"
				ima={ima}
				size="cover"
				position="initial"
			/>

			<section className="contactWrapperSection">
				<div className="formWrapper">
					<div className="titleWrapper">
						<h1>Questions?</h1>
						<h1>Contact Us :)</h1>
					</div>

					<div>
						<Form />
					</div>
				</div>
				<div className="mapCol">
					<h5 className="title">Adept Services Headquarters</h5>
					<h5 className="address">
						9 George Street, North Strathfield, NSW, 2137
					</h5>
					<p className="email">info@adept-services.com.au</p>
					<p className="number">1300 029 262</p>

					<div className="mapWrapper">
						<div className="gmapCanvas">
							<iframe
								title="adeptMap"
								className="mapCanvas"
								id="gmap_canvas"
								src="https://maps.google.com/maps?q=9%20George%20Street,%20North%20Strathfield,%20NSW,%202137&t=&z=13&ie=UTF8&iwloc=&output=embed"
								frameBorder="0"
								scrolling="no"
								marginHeight={0}
								marginWidth={0}
							></iframe>
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
