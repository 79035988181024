import React from "react";
import "./Card.scss";

type props = {
	k: any;
	ima: any;
	title: any;
	str: any;
	description: any;
	cla: string;
};

export const Card = ({ title, str, description, ima, k, cla }: props) => {
	return (
		<div className="cardContainer" id={cla} key={k}>
			{/* imagen */}
			<div className="imaContainer">
				<img
					className="photoPresentation"
					src={ima}
					alt="presentationServices"
				/>
			</div>
			{/* descripcion */}
			<div>
				<h3 className="cardTitle">{title}</h3>
				<p className="description">
					<strong>{str}</strong>
					{description}
				</p>
			</div>
		</div>
	);
};
