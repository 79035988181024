import React from "react";
import "./Hero.scss";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

export const Hero = () => {
	const link = () => {
		return <Link to="/services">Services</Link>;
	};

	return (
		<section className="hero-section">
			<div className="text-container">
				<h1>Cleaning Services</h1>
				<p>
					Adept Services start with a full understanding of your specific
					requirements and then develop a detailed cleaning, safety and
					environmental plan for every area of the site.
				</p>
				<div className="buttonContainer">
					<Button st="transparent" title="Read More..." click={() => link} />
				</div>
			</div>
		</section>
	);
};
