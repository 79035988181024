import React from "react";
import "./BannerTitle.scss";

type props = {
	title: string;
	ima: string;
	size: string;
	position: string;
};

export const BannerTitle = ({ title, ima, size, position }: props) => {
	const divStyle = {
		background: "rgba(0,0,0,0.4) url(" + ima + ") no-repeat",
		backgroundSize: size,
		backgroundPosition: position,
		backgroundColor: "rgba(255, 255, 255, 0.4)",
	};
	return (
		<section className="bannerSection">
			<div className="bannerImage" style={divStyle}>
				<h2 className="bannerTitle">{title}</h2>
			</div>
		</section>
	);
};
