import React, { useState, useEffect } from "react";
import "./Home.scss";
import { servicesCards, clients } from "./HomeData";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ima from "../../assets/banners/cleaningGenericBanner.png";
/*components*/
import { Hero } from "../../components/Hero/Hero";
import { Card } from "../../components/Card/Card";
import { Title } from "../../components/Title/Title";
import { BannerTitle } from "../../components/BannerTitle/BannerTitle";

const responsive = {
	0: { items: 1 },
	568: { items: 2 },
	1024: { items: 3 },
};
export const Home = () => {
	const [windowWidth, setWindowWidth] = useState(0);

	useEffect(() => {
		setWindowWidth(window.innerWidth);
	}, []);

	function handleResize() {
		setWindowWidth(window.innerWidth);
	}

	window.addEventListener("resize", handleResize);

	const renderCardList = servicesCards.map((el) => (
		<Card
			k={el.id}
			ima={el.ima}
			title={el.title}
			str={el.str}
			description={el.description}
			cla={el.cla}
		/>
	));

	return (
		<div>
			<Hero />

			<section className="our-services-section">
				<Title text="Our Services" />
				<div className="card_content">{renderCardList}</div>
			</section>
			<section className="banner-section">
				<BannerTitle ima={ima} position="right" size="cover" title="" />
			</section>
			<section className="our-clients-section">
				<Title text="Our Clients" />
				<AliceCarousel
					disableButtonsControls
					responsive={responsive}
					mouseTracking
					items={clients}
				/>
			</section>
		</div>
	);
};
