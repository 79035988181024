import React from "react";
import logo from "../../assets/logos/Adept_service_400x139.png";
import { Link } from "react-router-dom";

export const Logo = () => {
	return (
		<div>
			<Link to={"/"}>
				<img style={{ width: "150px" }} alt="logo" src={logo} />
			</Link>
		</div>
	);
};
