import React from "react";
import "./LoaderPage.scss";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import secondLottie from "../../assets/lottie/lf30_editor_nl0pa62g.json";

export const LoaderPage = () => {
	return (
		<section className="loaderPage">
			<Player
				autoplay
				loop
				src={secondLottie}
				style={{ height: "20rem", width: "20rem" }}
			>
				<Controls
					visible={false}
					buttons={["play", "repeat", "frame", "debug"]}
				/>
			</Player>
		</section>
	);
};
