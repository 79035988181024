import React from "react";
import "./Button.scss";
import { Link } from "react-router-dom";

type props = {
	title: string;
	click: any;
	st: string;
};

const Button = ({ click, title, st }: props) => {
	return (
		<Link className={st} to="/services">
			{title}
		</Link>
	);
};

export default Button;
