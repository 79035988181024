export const servicesCards = [
	{
		id: "1",
		cla: "item1",
		ima: require("../../assets/images/cleaningService.png"),
		title: "CLEANING",
		str: "Adept Services",
		description:
			" start with a full understanding of your specific requirements and then develop a detailed cleaning, safety and enviromental plan for every area of the site.",
	},
	{
		id: "2",
		cla: "item2",
		ima: require("../../assets/images/techService.png"),
		title: "TECHNOLOGY",
		str: "Adept Services",
		description:
			" strong IT capabilities enables to develop systems that are designed to serve you better. Our proprietary services platform streamlines various business processes, so that you benefit from an efficient and integrated service delivery that is customised to suit your reporting and business needs.",
	},
	{
		id: "3",
		cla: "item3",
		ima: require("../../assets/images/maintenanceService.png"),
		title: "MAINTENANCE",
		str: "Adept Maintenance Services",
		description:
			" provides an extensive range of maintenance services to industrial sites and facilities. We take a whole-of-life approach to asset management and maintenance, with the objective of achieving meaningful outcomes for our customers.",
	},
];

export const handleDragStart = (e: any) => e.preventDefault();

export const clients = [
	<img
		alt="logo-client"
		style={{ width: "300px" }}
		src={require("../../assets/logos/logoIndependentWarehouseSolutions.png")}
		onDragStart={handleDragStart}
		role="presentation"
	/>,
	<img
		alt="logo-client"
		style={{ width: "300px" }}
		src={require("../../assets/logos/logoMeriton.png")}
		onDragStart={handleDragStart}
		role="presentation"
	/>,
	<img
		alt="logo-client"
		style={{ width: "300px" }}
		src={require("../../assets/logos/logoMontessoriAcademy.png")}
		onDragStart={handleDragStart}
		role="presentation"
	/>,
	<img
		alt="logo-client"
		style={{ width: "300px" }}
		src={require("../../assets/logos/logoOneSteel.png")}
		onDragStart={handleDragStart}
		role="presentation"
	/>,
	<img
		alt="logo-client"
		style={{ width: "300px" }}
		src={require("../../assets/logos/logoPhilTerry.png")}
		onDragStart={handleDragStart}
		role="presentation"
	/>,
	<img
		alt="logo-client"
		style={{ width: "300px" }}
		src={require("../../assets/logos/logoReflections.png")}
		onDragStart={handleDragStart}
		role="presentation"
	/>,
	<img
		alt="logo-client"
		style={{ width: "300px" }}
		src={require("../../assets/logos/logoSribalGroup.png")}
		onDragStart={handleDragStart}
		role="presentation"
	/>,
];
