export const managementSystem = [
	{
		id: "1",
		text: "Pest Control",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "2",
		text: "Caretaking Services",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "3",
		text: "Trade Services",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "4",
		text: "Labour Hire Services",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "5",
		text: "Handyman Services",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "6",
		text: "Electrical Testing & Tagging",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "7",
		text: "Managed Wahroom Supplies & Services",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "8",
		text: "Landscape Maintenance",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "9",
		text: "Removalist Services",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "10",
		text: "Machine Sweeping & Blowing",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "11",
		text: "Indoor Plant Hire",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "12",
		text: "catering Services",
		ima: require("../../assets/icons/arrowList.png"),
	},
	{
		id: "13",
		text: "Refurb & Strip Outs",
		ima: require("../../assets/icons/arrowList.png"),
	},
];
