export const data = [
	{
		id: "1",
		text: "Commercial",
		ima: require("../../assets/icons/IconAdept_AboutUs_1.png"),
	},
	{
		id: "2",
		text: "Industrial",
		ima: require("../../assets/icons/IconAdept_AboutUs_2.png"),
	},
	{
		id: "3",
		text: "Residential",
		ima: require("../../assets/icons/IconAdept_AboutUs_3.png"),
	},
	{
		id: "4",
		text: "Hospitality",
		ima: require("../../assets/icons/IconAdept_AboutUs_4.png"),
	},
	{
		id: "5",
		text: "Hotel Services",
		ima: require("../../assets/icons/IconAdept_AboutUs_5.png"),
	},
	{
		id: "6",
		text: "Construction",
		ima: require("../../assets/icons/IconAdept_AboutUs_6.png"),
	},
	{
		id: "7",
		text: "Aged/Health Care",
		ima: require("../../assets/icons/IconAdept_AboutUs_7.png"),
	},
];
